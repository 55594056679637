import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const SliderComponent = props => {
  const sliderItems = props.sliderItems
  function assetsToDisplay(item) {
    if (item.mimeType === "video/mp4") {
      return (
        <video className="lg:h-full lg:w-full" controls>
          <source
            src={item.url}
            type="video/mp4"
          />
        </video>
      )
    } else {
      return (
        <img alt="slider" src={item.url} className="h-full w-full"></img>
      )
    }
  }

  const params = {
    pagination: {
      el: ".swiper-pagination-test",
      clickable: true,
      renderBullet: (index, className) => {
        return (
          '<img src="/img/Slider_indicator-02.svg"  class=" bullet ' +
          className +
          '"></img>'
        )
      },
    },
    spaceBetween: 50,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: (sliderItems.length>1) ? 5000 : 3600000,
      disableOnInteraction: true, // TODO check if we want to change it
    },
    scrollbar: { draggable: true },
  }

  return (
    <div className="block">
      <Swiper {...params}>
        {sliderItems.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="relative h-full">
              {assetsToDisplay(item)}
            </div>
          </SwiperSlide>
        ))}

        <div className="dashed-separator mb-5 mt-1 swiper-pagination-test"></div>
      </Swiper>
    </div>
  )
}

export default SliderComponent

// Change Service slider to component slider and adjust in itemdetails.js
