import React from "react"
import { FaDownload } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import SliderComponent from "./SliderComponent"
import { withTrans } from "../withTrans"
import parse, { domToReact } from "html-react-parser"

const ItemDetails = props => {
  //options - To replace and add underline style to links
  const options = {
    replace: ({ attribs, name, children }) => {
      if (name === "a") {
        return (
          <a
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
            href={attribs.href}
          >
            {domToReact(children, options)}
          </a>
        )
      }
    },
  }

  function renderBackButton(detailsType) {
    return (
      <button
        onClick={() => {
          if (window.history.length > 2) {
            window.history.back()
          } else {
            //TODO - Fix back on no history
            console.log();
          }
        }}
        className="font-futurastd_book mt-2 text-sm"
      >
        {/*
        TODO: label_back_to_services "Back to All Services" replaced with generic "Back"
          to allow for generic back-text when service is access from services or lab_services
        */}
         &#60;{" "}
        {props.t("label_back_to_" + detailsType)}{" "}
      </button>
    )
  }
  function renderDownloads() {
    switch (props.detailsType) {
      case "services":
        return (
          <div className="col-span-1 font-futurastd_book text-xs uppercase">
            <div className="mb-4 md:mb-0">
              <a
                href={props.detailItem.specs_document.url}
                target="_blank"
                rel="noreferrer"
              >
                <FaDownload className="inline" />{" "}
                {props.t("title_download_specs")}
              </a>
            </div>
            <div>
              <a
                href="mailto:info@cimatheque.org"
                target="_blank"
                rel="noreferrer"
              >
                <MdEmail className="inline" />{" "}
                {props.t("title_email_us_for_quotations")}
              </a>
            </div>
          </div>
        )

      case "tutorials":
        return (
          <div className="col-span-1 font-futurastd_book text-xs uppercase">
            <div className="mb-4 md:mb-0">
              <a
                href={props.detailItem.tutorial_document.url}
                target="_blank"
                rel="noreferrer"
              >
                <FaDownload className="inline" />{" "}
                {props.t("title_download_tutorial")}
              </a>
            </div>
          </div>
        )

      default:
        break
    }
  }

  return (
    <div className="mx-3 lg:mx-7">
      <SliderComponent sliderItems={props.detailItem.sliderImages} />
      <div className="grid grid-col-1 md:grid-cols-5 mx-7 gap-4">
        <div className="col-span-1">
          <p className="font-futurastd_heavy">{props.detailItem.title}</p>
          {renderBackButton(props.detailsType)}
        </div>

        <div className="col-span-1 md:col-span-3 font-futurastd_book text-xs order-last md:order-none">
          {parse(props.detailItem.longDescription.html, options)}
        </div>
        {renderDownloads()}
      </div>
    </div>
  )
}
export default withTrans(ItemDetails)
